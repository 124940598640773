// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** background **/
  --ion-background-color: #fafafa;
  /** primary **/
  --ion-color-primary: #c81929;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #053967;
  --ion-color-primary-tint: #6BAAE2;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--ion-color-primary);
  --ion-color-secondary-tint: var(--ion-color-primary);

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #E61196;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ebebeb;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #AD0D71;
  --ion-color-success-tint: var(--ion-color-primary);

  /** warning **/
  --ion-color-warning: #ffa600;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e08600;
  --ion-color-warning-tint: #ffaf1a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** transparent background **/
  --ion-color-transparent: transparent;

  /** font size **/
  --main-sm-text: 12px;
  --main-mid-text: 16px;
  --main-lg-text: 20px;
  --main-xlg-text: 24px;

  /** padding **/
  --main-padding: 16px;
  --main-padding-sm: 8px;
  --main-padding-lg: 32px;

   /** margin **/
   --main-margin: 16px;

    /** button radius **/
    --main-key-btn-radius: 8px;
    --main-sm-btn-radius: 4px;
    --main-round-btn-radius: 100px;

    /** solid button style **/
    --main-key-btn-solid: var(--ion-color-primary);
    --main-key-btn-solid-text: var(--ion-color-primary-contrast);

    /** solid button style **/
    --main-key-btn-outline: var(--ion-color-primary);
    --main-key-btn-outline-text: var(--ion-color-primary);

    /** key button shadow **/
    --main-card-shadow: 0 4px 16px rgba(0,0,0,.12);
    --main-key-btn-shadow: 0px 0px 16px rgba(0,0,0,.22);

    --image-ratio:100%;
//     --pay-gradient-start:#ec8b00;
//     --pay-gradient-end:#ecad00;
    --pay-text:#fff;

    --grid-items-per-row: 2;

    --toolbar-bg:var(--ion-color-primary);
    --back-btn: #ffffff;
    --chip-badge: #ffffff;
    --chip-num: #000000;
    --content-bg: #f1f1f1;
    --itemlist-bg:  #ffffff;
    --historytext-color: #ffffff;
    --historybtn-bg: #222428;
    --historybtn-active: #222428;
    --startext-color: #ffffff;
    --startbtn-bg: var(--ion-color-primary);
    --startbtn-active: var(--ion-color-primary);
    --startbtn-shadow: 0px 0px 16px rgba(0,0,0,.22);
    --table-num: #000000;
    --tablenum-shadow: none;
    --display-store: block;
    --store-name: var(--ion-color-primary);
    --store-location: #000000;
    --storeaddr-shadow: rgba(255, 255, 255,0.8) 2px 0px 0px, rgba(255, 255, 255,0.8) 1.75517px 0.958851px 0px, rgba(255, 255, 255,0.8) 1.0806px 1.68294px 0px, rgba(255, 255, 255,0.8) 0.141474px 1.99499px 0px, rgba(255, 255, 255,0.8) -0.832294px 1.81859px 0px, rgba(255, 255, 255,0.8) -1.60229px 1.19694px 0px, rgba(255, 255, 255,0.8) -1.97998px 0.28224px 0px, rgba(255, 255, 255,0.8) -1.87291px -0.701566px 0px, rgba(255, 255, 255,0.8) -1.30729px -1.5136px 0px, rgba(255, 255, 255,0.8) -0.421592px -1.95506px 0px, rgba(255, 255, 255,0.8) 0.567324px -1.91785px 0px, rgba(255, 255, 255,0.8) 1.41734px -1.41108px 0px, rgba(255, 255, 255,0.8) 1.92034px -0.558831px 0px;
    --storename-shadow: rgba(255, 255, 255, 0.8) 1px 0px 0px, rgba(255, 255, 255, 0.8) 0.540302px 0.841471px 0px, rgba(255, 255, 255, 0.8) -0.416147px 0.909297px 0px, rgba(255, 255, 255, 0.8) -0.989992px 0.14112px 0px, rgba(255, 255, 255, 0.8) -0.653644px -0.756802px 0px, rgba(255, 255, 255, 0.8) 0.283662px -0.958924px 0px, rgba(255, 255, 255, 0.8) 0.96017px -0.279415px 0px;
    --splashinfo-bg: rgba(253, 252, 248, 0);
    --ext-txt: var(--ion-color-primary);
    --primary-text-color: var(--ion-color-primary);
}

$colors: (
        // primary:    #4587f0,
        // secondary:  #F06C06,
        // danger:     #F44812,
        // opbrand: #EBAB44,
        // light:      #e2e2e2,
        // dark:       #222,
        // button:      #2ab3b9,
        // contrast: #fff,
        // success:     #22AE73,
        // light-text : #939393,
        // text-color: #000,
        // background-color: #f2f0f0,
        // toolbar-background: #222,
        // brand: #2a2928,
        // brand-sub:  #2a2928,
        // light-grey: #b3b3b3,
);

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

//@import "ionic.theme.default";



// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/

$ionicons-font-path: "../../assets/fonts";
//@import "ionicons";


$item-md-detail-push-show: true;

// @import "lpStyle";
// @import "shakeShackStyle";
// @import "yoshinoyaStyle";
@import "nandos";
